#header {
  backdrop-filter: blur(5px) !important;
}

.connect_btn {
  width: 200px !important;
  height: 50px !important;
  color: white;
  border: 10px;
  border-color: aliceblue;
}
                         